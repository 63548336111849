<template>
	<SelectWithChips
		v-model="filters.members"
		:disabled="members.length === 0"
		:label="$t('client_workspace.task.members')"
		:items="members"
		:item-value="'id'"
		:item-text="'name'"
		:item-short-text="itemShortText"
		:minimized="isMinimized"
		multiple
		:prepend-icon="$vuetify.breakpoint.mdAndDown ? null : 'person'"
		:max-selected-items-showed="minimized ? 0 : 1"
	/>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerFilterMember',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			filters: state => state.tasks.filters,
			members: state => state.tasks.members
		}),
		isMinimized: function () {
			return this.minimized || this.$vuetify.breakpoint.lgAndDown
		},
		itemShortText: function () {
			return this.isMinimized ? 'initials' : null
		}
	}
}
</script>
